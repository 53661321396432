import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinkWrapper = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  transition: 'background-color 0.2s ease-in-out',
  minHeight: 49,

  '&:hover': {
    backgroundColor: theme.palette.primaryColorShades.p8,
  },
}));
