import { Divider } from '@mui/material';
import Slide from '@mui/material/Slide';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import HeaderBasket from '@/components/HeaderBasket/HeaderBasket';
import HeaderEmail from '@/components/HeaderEmail/HeaderEmail';
import IntlHeaderEmail from '@/components/IntlHeaderEmail/IntlHeaderEmail';
import LanguagePagePicker from '@/components/LanguagePagePicker/LanguagePagePicker';
import PhoneOutageBanner from '@/components/PhoneOutageBanner/PhoneOutageBanner';
import Dialog from '@/components/ui/Dialog/Dialog';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import getConfig from '@/configs/global';
import { internationalPagesPaths } from '@/constants/internationalPages';
import { trackHeaderPhoneClick } from '@/helpers/dataLayer';
import { formatPhoneToLink, updatePhoneNumbers } from '@/helpers/utils';
import usePhoneNumber from '@/hooks/usePhoneNumber';

import MobileNavigation from './lib/MobileNavigation';
import {
  HeaderLogo,
  IconsWrapper,
  LogoRow,
  PhoneNumber,
  Wrapper,
} from './MobileHeader.style';

const navigationLinks = PropTypes.arrayOf(
  PropTypes.shape({
    /** Displayed name of link. */
    name: PropTypes.string.isRequired,
    /** Url path as computed */
    href: PropTypes.string,
    /** 2nd layer navigation links */
    children: PropTypes.array,
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" unmountOnExit ref={ref} {...props} />;
});

/**
 * MobileHeader.
 */
function MobileHeader({
  navigation,
  bottomNavigation,
  sortLinks,
  pagesTitle,
  basketHidden,
  languagePagePickerHidden,
  actionIcons,
  phoneNumber: phone,
  mrktClassNamePhone,
  mrktClassNameMail,
}) {
  const [onPageTop, setOnPageTop] = useState(true);
  const [scrollingDown, setScrollingDown] = useState(false);
  const [mobileUnfolded, setMobileUnfolded] = useState(false);
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();
  const pageHeader = useRef();
  const { pathname } = useRouter();
  const {
    publicRuntimeConfig: {
      FEAT_FLAG_PHONE_OUTAGE,
      FEAT_FLAG_HIDE_PHONE_NUMBER,
    },
  } = getConfig();

  /** Outside the state to avoid rendering on every scroll. */
  const prevScrollPos = useRef(0);

  /**
   * Recognize when scroll is on top
   * and when scrolling is going downwards.
   */
  const handleScroll = () => {
    if (pageHeader.current) {
      const pageHeaderHeight = pageHeader.current.offsetHeight;
      setOnPageTop(window.scrollY <= pageHeaderHeight);
      setScrollingDown(window.scrollY > prevScrollPos.current);
      prevScrollPos.current = window.scrollY;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    updatePhoneNumbers();

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const toggleFold = () => {
    setMobileUnfolded(prevState => !prevState);
  };

  const closeFold = () => setMobileUnfolded(false);

  const renderMobileNavigation = () => (
    <Dialog
      open={mobileUnfolded}
      fullScreen
      TransitionComponent={Transition}
      hideBackdrop
      PaperProps={{ sx: { overflowX: 'hidden' } }}>
      <MobileNavigation
        pagesTitle={pagesTitle}
        links={navigation}
        bottomLinks={bottomNavigation}
        sortLinks={sortLinks}
        closeNavigation={() => setMobileUnfolded(false)}
      />
    </Dialog>
  );

  return (
    <>
      <Wrapper
        onPageTop={onPageTop}
        scrollingDown={scrollingDown}
        data-cy="page header mobile"
        ref={pageHeader}
        className="mui-fixed">
        {FEAT_FLAG_PHONE_OUTAGE === true && <PhoneOutageBanner />}
        {!FEAT_FLAG_HIDE_PHONE_NUMBER && (
          <PhoneNumber
            component="a"
            variant="body2"
            href={phone ? formatPhoneToLink(phone) : phoneNumberLink}
            data-cy="link: phone mobile"
            className={mrktClassNamePhone || 'mrkt-hp_phone'}
            onClick={trackHeaderPhoneClick}>
            <SvgIcon name="phone" color="primary" sx={{ marginRight: 1 }} />
            <span className="mrsCallTracking">{phone || phoneNumber}</span>
          </PhoneNumber>
        )}
        <LogoRow>
          <IconsWrapper
            direction="row"
            autoWidth={
              !(languagePagePickerHidden || basketHidden || !navigation)
            }>
            {navigation && (
              <SvgIcon
                data-cy="button: burger"
                name={mobileUnfolded ? 'close' : 'menu'}
                onClick={toggleFold}
                color="primary"
              />
            )}
          </IconsWrapper>
          <HeaderLogo onClick={closeFold} data-cy="link: header logo mobile" />
          <IconsWrapper
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            autoWidth={
              !(languagePagePickerHidden || basketHidden || !navigation)
            }>
            {actionIcons}
            {!languagePagePickerHidden && <LanguagePagePicker />}
            {!basketHidden && (
              <>
                <HeaderBasket data-cy="headerBasket mobile" />
                <Divider orientation="vertical" sx={{ height: 24 }} />
              </>
            )}
            {internationalPagesPaths.includes(pathname) ? (
              <IntlHeaderEmail mrktClassName={mrktClassNameMail} />
            ) : (
              <HeaderEmail
                data-cy="link: email mobile"
                mrktClassName={mrktClassNameMail}
              />
            )}
          </IconsWrapper>
        </LogoRow>

        {navigation && renderMobileNavigation()}
      </Wrapper>
    </>
  );
}

MobileHeader.propTypes = {
  /** Structure of 1st, 2nd and 3rd navigation links. */
  navigation: navigationLinks,
  /** Structure of bottom navigation links. */
  bottomNavigation: navigationLinks,
  /** Boolean flag to sort 1st level links by name or not */
  sortLinks: PropTypes.bool,
  /** Title of the pages */
  pagesTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  /** Should hide language picker */
  languagePagePickerHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

MobileHeader.defaultProps = {
  bottomNavigation: [],
  pagesTitle: <FormattedMessage id="header_category" />,
  sortLinks: true,
  basketHidden: false,
  navigation: null,
  languagePagePickerHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default MobileHeader;
