import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import PhoneOutageBanner from '@/components/PhoneOutageBanner/PhoneOutageBanner';
import getConfig from '@/configs/global';

import LowerBar from './LowerBar/LowerBar';
import MegaMenu from './MegaMenu/MegaMenu';
import UpperBar from './UpperBar/UpperBar';

const DesktopHeader = ({
  navigation,
  basketHidden,
  languagePagePickerHidden,
  actionIcons,
  phoneNumber,
  mrktClassNamePhone,
  mrktClassNameMail,
}) => {
  const {
    publicRuntimeConfig: { FEAT_FLAG_PHONE_OUTAGE },
  } = getConfig();
  const menuWrapperRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    const updateNavbarHeight = () => {
      if (menuWrapperRef.current) {
        setNavbarHeight(menuWrapperRef.current.clientHeight);
      }
    };

    updateNavbarHeight();
    window.addEventListener('resize', updateNavbarHeight);
    return () => {
      window.removeEventListener('resize', updateNavbarHeight);
    };
  }, []);

  return (
    <Stack
      ref={menuWrapperRef}
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: theme => theme.zIndex.appBar,
      }}
      data-cy="page header desktop">
      {FEAT_FLAG_PHONE_OUTAGE === true && <PhoneOutageBanner />}
      <Box>
        <UpperBar
          phoneNumber={phoneNumber}
          mrktClassNamePhone={mrktClassNamePhone}
          mrktClassNameMail={mrktClassNameMail}
        />
        <LowerBar
          hasNavigation={!!navigation}
          languagePagePickerHidden={languagePagePickerHidden}
          basketHidden={basketHidden}
          actionIcons={actionIcons}
        />
        {navigation && (
          <MegaMenu navigation={navigation} navbarHeight={navbarHeight} />
        )}
      </Box>
    </Stack>
  );
};

DesktopHeader.propTypes = {
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

DesktopHeader.defaultProps = {
  basketHidden: false,
  languagePagePickerHidden: false,
  navigationHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default DesktopHeader;
