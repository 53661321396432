import { styled } from '@mui/material/styles';

export const BottomLinkStyled = styled('div')(({ theme }) => ({
  ...theme.typography.caption,
  '& *': {
    padding: 0,
    margin: 0,
    color: theme.palette.grayscale[500],
  },

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));
