import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import { getChildren, sortByTitle } from '../../utils';
import Link from '../Link/Link';
import MenuItem from '../MenuItem/MenuItem';
import { BackLink, Level, Links } from '../MobileNavigation.style';

const Level3 = ({ level, activeLevel, closeNavigation, onGoBackClick }) => {
  const formatMessage = useFormatMessage();
  return (
    <Level>
      <Links active={activeLevel === 3}>
        <BackLink onClick={onGoBackClick}>
          {level && (
            <Typography variant="h4" color="primary">
              {level.attributes.title}
            </Typography>
          )}
          <CloseIcon color="primary" />
        </BackLink>
        {level?.attributes?.link && (
          <Link
            href={level.attributes.link}
            title={`${formatMessage('header_allin')} ${level.attributes.title}`}
            closeNavigation={closeNavigation}
            className={`mrkt-head_kat_${level?.attributes?.link.replace(
              /\//g,
              ''
            )}`}
            data-cy={`mobile nav: all ${level.attributes.title}`}
          />
        )}
        {sortByTitle(getChildren(level)).map((item, index) => (
          <MenuItem item={item} closeNavigation={closeNavigation} key={index} />
        ))}
      </Links>
    </Level>
  );
};

Level3.propTypes = {
  level: PropTypes.object,
  activeLevel: PropTypes.number.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  onGoBackClick: PropTypes.func.isRequired,
};

export default Level3;
