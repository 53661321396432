import { Box, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import HeaderLogo from '@/components//HeaderLogo/HeaderLogo';
import HeaderBasket from '@/components/HeaderBasket/HeaderBasket';
import HeaderEmail from '@/components/HeaderEmail/HeaderEmail';
import HeaderPhone from '@/components/HeaderPhone/HeaderPhone';
import IntlHeaderEmail from '@/components/IntlHeaderEmail/IntlHeaderEmail';
import LanguagePagePicker from '@/components/LanguagePagePicker/LanguagePagePicker';
import PhoneOutageBanner from '@/components/PhoneOutageBanner/PhoneOutageBanner';
import getConfig from '@/configs/global';
import { internationalPagesPaths } from '@/constants/internationalPages';
import { trackHeaderPhoneClick, trackMenuItemClick } from '@/helpers/dataLayer';

import { Container, Divider, Header, Wrapper } from './DesktopHeader.style';
import Navigation from './Navigation';

const DesktopHeader = ({
  style,
  basketHidden,
  languagePagePickerHidden,
  navigation,
  actionIcons,
  phoneNumber,
  mrktClassNamePhone,
  mrktClassNameMail,
}) => {
  const [navigationHovered, setNavigationHovered] = useState(false);
  const { pathname } = useRouter();
  const {
    publicRuntimeConfig: {
      FEAT_FLAG_PHONE_OUTAGE,
      FEAT_FLAG_HIDE_PHONE_NUMBER,
    },
  } = getConfig();

  const [height, setHeight] = useState(navigation ? 99 : 67);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <>
      <Wrapper
        elevation={1}
        data-cy="page header desktop"
        style={style}
        className="mui-fixed"
        ref={measuredRef}>
        {FEAT_FLAG_PHONE_OUTAGE === true && <PhoneOutageBanner />}
        <Container navigationHovered={navigationHovered}>
          <Header>
            <HeaderLogo data-cy="link: header logo" />
            <Stack direction="row" alignItems="center" spacing={1}>
              {actionIcons}
              {!languagePagePickerHidden && <LanguagePagePicker />}
              {!basketHidden && (
                <>
                  <HeaderBasket data-cy="headerBasket desktop" />
                  <Divider orientation="vertical" />
                </>
              )}
              {internationalPagesPaths.includes(pathname) ? (
                <IntlHeaderEmail mrktClassName={mrktClassNameMail} />
              ) : (
                <HeaderEmail mrktClassName={mrktClassNameMail} />
              )}
              {!FEAT_FLAG_HIDE_PHONE_NUMBER && (
                <HeaderPhone
                  phoneNumber={phoneNumber}
                  mrktClassName={mrktClassNamePhone}
                  onClick={trackHeaderPhoneClick}
                />
              )}
            </Stack>
          </Header>
          {navigation && (
            <Navigation
              links={navigation}
              onMouseEnter={() => setNavigationHovered(true)}
              onMouseLeave={() => setNavigationHovered(false)}
              onLinkClick={() => {
                setNavigationHovered(false);
                trackMenuItemClick();
              }}
            />
          )}
        </Container>
      </Wrapper>
      <Box height={height} />
    </>
  );
};

DesktopHeader.propTypes = {
  /** Css theme. */
  style: PropTypes.object,
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  navigationHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

DesktopHeader.defaultProps = {
  style: {},
  basketHidden: false,
  languagePagePickerHidden: false,
  navigationHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default DesktopHeader;
