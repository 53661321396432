import BaseBackdrop from '@mui/material/Backdrop';
import BaseDialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const Backdrop = styled(BaseBackdrop, {
  shouldForwardProp: prop => prop !== 'visiblePhone',
})(({ theme, visiblePhone }) => ({
  [theme.breakpoints.down('md')]: {
    ...(visiblePhone && {
      top: 46,
    }),
  },
}));

export const Dialog = styled(BaseDialog, {
  shouldForwardProp: prop => !['mobileMenu', 'visiblePhone'].includes(prop),
})(({ theme, mobileMenu, visiblePhone }) => ({
  [theme.breakpoints.down('md')]: {
    ...(mobileMenu && {
      top: 100,
      // overlay below header
      zIndex: theme.zIndex.appBar - 1,
    }),

    ...(visiblePhone && {
      top: 46,
    }),
  },
}));
