import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ContactFormDialog from '@/components/ContactFormDialog/ContactFormDialog';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import getConfig from '@/configs/global';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useGenericContactFormContext } from '@/hooks/useGenericContactFormContext';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { EmailIconButton } from './HeaderEmail.style';

const HeaderEmail = ({ mrktClassName }) => {
  const {
    publicRuntimeConfig: { FEAT_FLAG_NEW_PAGE_HEADER },
  } = getConfig();
  const formatMessage = useFormatMessage();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const {
    contactFormData,
    resetContactFormData,
    updateContactFormData,
  } = useGenericContactFormContext();
  const mobileMediaQuery = useMobileMediaQuery();

  const email = contactFormData.email || formatMessage('header_email');

  const handleOpen = () => setContactFormOpen(true);

  const handleClose = () => setContactFormOpen(false);

  return (
    <>
      <EmailIconButton
        aria-label="email"
        onClick={handleOpen}
        title="Email"
        data-cy="headerEmailIcon"
        className={mrktClassName}>
        {FEAT_FLAG_NEW_PAGE_HEADER && !mobileMediaQuery ? (
          <>
            <SvgIcon
              name="mail"
              sx={{ color: theme => theme.palette.common.white }}
              size="small"
            />
            <Typography
              color="common.white"
              fontSize={theme => theme.typography.pxToRem(12)}
              ml={1}>
              {email}
            </Typography>
          </>
        ) : (
          <SvgIcon name="mail" color="primary" />
        )}
      </EmailIconButton>
      <ContactFormDialog
        open={contactFormOpen}
        defaultValues={contactFormData}
        onClose={handleClose}
        onChange={updateContactFormData}
        onEmailSent={resetContactFormData}
      />
    </>
  );
};

HeaderEmail.propTypes = {
  mrktClassName: PropTypes.string,
};

HeaderEmail.defaultProps = {
  mrktClassName: null,
};

export default HeaderEmail;
