import { styled } from '@mui/material/styles';

export const Partner = styled('li')({
  position: 'relative',
  listStyle: 'none',

  img: {
    objectFit: 'contain',
  },
});
