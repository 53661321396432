import { Grid, Stack, Typography } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import StrapiImage from '@/components/StrapiImage/StrapiImage';
import { trackMenuItemClick } from '@/helpers/dataLayer';

import { ImageWrapper } from './NavLinks.style';

const NavLinks = ({ menuItems, hasSideSection, sideSectionType }) => {
  if (!menuItems) {
    return null;
  }

  return menuItems.map(item => {
    const {
      id,
      attributes: {
        title,
        link,
        navbar_links: { data: subLinks },
        image,
      },
    } = item;

    const sanitizedPath = link ? link.replace(/\//g, '') : '';
    const marketingClassname = `mrkt-head_kat_${sanitizedPath}`;

    return (
      <Grid
        item
        xs={hasSideSection ? (sideSectionType === 'big' ? 6 : 4) : 2.4}
        lg={hasSideSection ? (sideSectionType === 'big' ? 4 : 3) : 2}
        key={id}>
        <Link
          href={link}
          className={marketingClassname}
          data-cy={`nav: ${title}`}
          onClick={trackMenuItemClick}>
          <ImageWrapper>
            <StrapiImage data={image.data} fill priority sizes="250px" />
          </ImageWrapper>
          <Typography
            component="span"
            variant="bodySmall2"
            color="primary.dark"
            mb={subLinks?.length > 0 ? 1.5 : 0}
            sx={{ display: 'block' }}>
            {title}
          </Typography>
        </Link>
        {subLinks?.length > 0 && (
          <Stack spacing={1.5}>
            {subLinks.map(item => {
              const {
                id,
                attributes: { title, link },
              } = item;
              return (
                <Typography
                  key={id}
                  component={Link}
                  href={link}
                  variant="captionRegular"
                  className="mrkt-third-level-category-head"
                  data-cy={`nav: ${title}`}
                  onClick={trackMenuItemClick}>
                  {title}
                </Typography>
              );
            })}
          </Stack>
        )}
      </Grid>
    );
  });
};

NavLinks.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        navbar_links: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                title: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
      }).isRequired,
    })
  ),
  hasSideSection: PropTypes.bool.isRequired,
  sideSectionType: PropTypes.string.isRequired,
};

export default NavLinks;
