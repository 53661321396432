import { styled } from '@mui/material/styles';

export const ImageWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 80,
  position: 'relative',
  img: {
    borderRadius: 4,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  marginBottom: theme.spacing(1),
}));
