import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import StrapiImage from '@/components/StrapiImage/StrapiImage';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Partner } from './Partners.style';

const imageHeight = 20;

const getImageWidth = ({ width, height }) => {
  if (typeof width === 'number' && typeof height === 'number') {
    return Math.round((imageHeight * width) / height);
  }
  return null;
};

const Partners = ({ partners }) => {
  const formatMessage = useFormatMessage();

  if (!partners || !partners.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography
        color="grayscale.500"
        sx={{
          fontSize: theme => theme.typography.pxToRem(10),
          textTransform: 'uppercase',
        }}>
        {formatMessage('header_partners')}
      </Typography>
      <Stack
        component="ul"
        direction="row"
        m={0}
        p={0}
        spacing={1}
        alignItems="center"
        flexWrap="wrap">
        {partners?.map(partner => {
          const imageWidth = getImageWidth(partner.attributes);
          return (
            <Partner
              key={partner.id}
              sx={{ width: imageWidth, height: imageHeight }}>
              <StrapiImage data={partner} fill sizes={`${imageWidth}px`} />
            </Partner>
          );
        })}
      </Stack>
    </Stack>
  );
};

Partners.propTypes = {
  partners: PropTypes.array,
};

export default Partners;
